footer {
    position: relative;
    height: 12rem;
    overflow-y: hidden;
    bottom: 0vh;

    @media only screen and (min-width: 500px) {
        height: 17rem;
    }
}

.wrapPic {
    position: absolute;
    bottom: 0;
    left: 0;
}

.wrapPic img {
    position: absolute;
}

.wrapPic img:first-child {
    left: -13rem;
    bottom: -9rem;
    width: 28rem;
    z-index: 3;

    @media only screen and (min-width: 500px) {
        width: 34rem;
    }

    @media only screen and (min-width: 1000px) {
        width: 37rem;
    }
}

.wrapPic img:nth-child(2) {
    left: -11rem;
    bottom: 0;
    width: 22rem;
    z-index: 1;

    @media only screen and (min-width: 500px) {
        width: 28rem;
    }

    @media only screen and (min-width: 1000px) {
        width: 31rem;
    }
}

.wrapPic img:last-child {
    left: 3rem;
    bottom: -1rem;
    width: 9rem;
    z-index: 2;
    transform: rotate(90deg);

    @media only screen and (min-width: 500px) {
        left: 8rem;
        width: 12rem;
    }

    @media only screen and (min-width: 1000px) {
        left: 10rem;
        width: 14rem;
    }

    @media only screen and (min-width: 1200px) {
        left: 10rem;
        width: 14rem;
    }
}

.wrapPic ~ img {
    position: absolute;
    bottom: 0;
    width: 17rem;
    left: 70%;

    @media only screen and (min-width: 700px) {
        width: 28rem;
        left: 50%;
    }
}