.restaurant, .hoverRestaurant {
    padding: 0.8rem 1.5rem;
    border-radius: 30px;
    margin-bottom: 1rem;
}

.restaurant {
    position: relative;
}

.miesmuschelRestaurant {
    background-color: #397439;
    color: white;
    text-align: center;
    overflow: hidden;
}

.miesmuschelRestaurant:hover > .hoverRestaurant {
    opacity: 0;
    bottom: -20rem;
    left: -20rem;
}

.hoverRestaurant {
    position: absolute;
    opacity: 0;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.restaurant:hover > .hoverRestaurant {
    opacity: 1;
    background-color: #397439;
}

.update, .delete {
    text-decoration: none;
    color: white;
}

.update:hover, .delete:hover {
    cursor: pointer;
    font-weight: bolder;
}