#root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.restaurantAdd {
  width: 50%;
  text-align: center;
}

h3 {
  font-family: "Lilita One";
  font-size: 2rem;
  padding-bottom: 1rem;
}

.restaurantField, .speisenField {
  margin-bottom: .8rem;
}

.restaurantField label, .restaurantField input,
.speisenField label, .speisenField input{
  display: block;
  width: 20rem;
  border-radius: 10px;
  margin: 0 auto;
  text-align: start;
}

.restaurantField input, .speisenField input{
  padding: 0.5rem;
  border: 1px solid black;
}

.restaurantField label, .speisenField label {
  font-size: 1.3rem;
}

input.invalid {
  background-color: red;
  border: red;
}

p.invalid {
  color: red;
}

button {
  padding: 1rem;
  background-color: black;
  color: white;
  font-size: 1.3rem;
  font-family: "Lilita One";
  cursor: pointer;
  border-radius: 30px;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc !important;
  color: #292929 !important;
  cursor: not-allowed;
  border: none;
}

.wrapRestaurantUpdate {
  padding-top: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: fit-content;
  }
}