header {
    position: relative;
    height: 20rem;

    @media only screen and (min-width: 1000px) {
        height: 30rem;
    }
}


.headerPoints {
    position: absolute;
    top: 1rem;
    left: 40%;
    width: 7rem;
    z-index: 1;

    @media only screen and (min-width: 800px) {
        width: 11rem;
    }

    @media only screen and (min-width: 1000px) {
        left: 45%;
    }
}

.wrapSchnitzel {
    position: absolute;
    top: -3rem;
    left: 45%;

    @media only screen and (min-width: 500px) {
        top: 0;
    }

    @media only screen and (min-width: 1000px) {
        left: 50%
    }
}

.wrapSchnitzel img:first-child {
    position: absolute;
    top: 0;
    width: 16rem;

    @media only screen and (min-width: 800px) {
        width: 23rem;
    }
}

.wrapSchnitzel img:last-child {
    position: absolute;
    top: -6.9rem;
    width: 16rem;
    left: .5rem;
    rotate: 90deg;

    @media only screen and (min-width: 800px) {
        left: 1.5rem;
        top: -8rem;
        width: 20rem;
    }
}

.circleHeader {
    position: absolute;
    bottom: 0;
    right:0;
}

.circleHeader img:first-child {
    position: absolute;
    bottom: 0;
    width: 10rem;
    right: -4rem;

    @media only screen and (min-width: 500px) {
        width: 13rem;
    }

    @media only screen and (min-width: 1000px) {
        width: 20rem;
        right: 4rem;
    }
}

.circleHeader img:last-child {
    position: absolute;
    width: 7rem;
    right: -2.5rem;
    bottom: 0rem;

    @media only screen and (min-width: 500px) {
        width: 9rem;
    }

    @media only screen and (min-width: 1000px) {
        width: 17rem;
        right: 0;
        bottom: 0;
    }
}

h1 {
    font-family: "Lilita One";
    font-size: 2rem;
    padding: 8.5rem 0 0 3rem;

    @media only screen and (min-width: 500px) {
        font-size: 4rem;
        padding: 8rem 0 0 3rem;
    }

    @media only screen and (min-width: 1000px) {
        font-size: 6rem;
        padding: 7rem 0 0 7rem;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 7rem;
    }
}

h2 {
    padding-left: 3rem;
    font-family: Quicksand;
    font-weight: 400;
    font-size: 1.5rem;

    @media only screen and (min-width: 1000px) {
        padding-left: 7rem;
        font-size: 2.5rem;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 3rem;
    }
}