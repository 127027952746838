.restaurantAdd {
    width: 100%;
    text-align: center;
    padding-top: 3rem;

    @media only screen and (min-width: 800px) {
        width: 50%;
        padding-top: 0rem;
    }
}

h3 {
    font-family: "Lilita One";
    font-size: 1.8rem;
    padding-bottom: 1rem;

    @media only screen and (min-width: 1000px) {
        font-size: 2rem;
    }
}

.restaurantField, .speisenField {
    margin-bottom: .8rem;
}

.restaurantField label, .restaurantField input,
.speisenField label, .speisenField input{
    display: block;
    width: 100%;
    border-radius: 10px;
    margin: 0 auto;
    text-align: start;

    @media only screen and (min-width: 500px) {
        width: 70%
    }

    @media only screen and (min-width: 700px) {
        width: 20rem
    }
}

.restaurantField input, .speisenField input{
    padding: 0.5rem;
    border: 1px solid black;
}

.restaurantField label, .speisenField label {
    font-size: 1.3rem;
}

input.invalid {
    background-color: red;
    border: red;
}

p.invalid {
    color: red;
}

button {
    padding: 1rem;
    background-color: black;
    color: white;
    font-size: 1.3rem;
    font-family: "Lilita One";
    cursor: pointer;
    border-radius: 30px;
    margin-bottom: .5rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
    background-color: #ccc !important;
    color: #292929 !important;
    cursor: not-allowed;
    border: none;
}