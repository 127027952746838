/* lilita-one-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lilita-one-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/lilita-one-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lilita-one-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lilita-one-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lilita-one-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lilita-one-v13-latin-regular.svg#LilitaOne') format('svg'); /* Legacy iOS */
}

/* quicksand-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/quicksand-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/quicksand-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/quicksand-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/quicksand-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/quicksand-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/quicksand-v30-latin-regular.svg#Quicksand') format('svg'); /* Legacy iOS */
}
/* quicksand-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/quicksand-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../fonts/quicksand-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/quicksand-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/quicksand-v30-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/quicksand-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/quicksand-v30-latin-700.svg#Quicksand') format('svg'); /* Legacy iOS */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
}

body {
  background-color: white;
  color: black;
  font-family: "Quicksand";
  font-weight: 400;
  overflow-x: hidden;
}

figure, img {
  width: 100%;
  height: auto;
}