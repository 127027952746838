
main {
    padding: 3rem 3rem;

    @media only screen and (min-width: 800px) {
        padding: 6rem 6rem;
    }

    @media only screen and (min-width: 1100px) {
        padding: 7rem 7rem;
    }

    @media only screen and (min-width: 1200px) {
        padding: 9rem 10rem;
    }

    @media only screen and (min-width: 1200px) {
        padding: 9rem 16rem;
    }

    @media only screen and (min-width: 1500px) {
        padding: 9rem 25rem;
    }

    @media only screen and (min-width: 1700px) {
        padding: 9rem 35rem;
    }
}

.restaurantsSection {
    text-align: center;
    padding-top: 7rem ;

    @media only screen and (min-width: 1000px) {
        padding: 7rem 0 0 0;
    }
}

.restaurantsSection h3 {
    font-size: 2rem;

    @media only screen and (min-width: 1000px) {
        font-size: 3rem;
    }
}

.wrapMiesAdd, .wrapRestaurants {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media only screen and (min-width: 800px) {
        justify-content: space-between
    }
}

.wrapRestaurants > div:nth-child(even), .wrapRestaurants > div:nth-child(even) > div {
    background-color: #EDDC63;
}

.wrapRestaurants > div:nth-child(odd), .wrapRestaurants > div:nth-child(odd) > div {
    background-color: #FF8F6E;
}

